:root {
  --kl-primary-900: #ff5351; // primary color
  --kl-primary-800: #ff6462;
  --kl-primary-700: #ff7574;
  --kl-primary-600: #ff8785;
  --kl-primary-500: #ff9897;
  --kl-primary-400: #FFD0D1;
  --kl-primary-300: #ffbab9;
  --kl-primary-200: #ffcbcb;
  --kl-primary-100: #ffdddc;
  --kl-primary-50: #ffeeee;
  --kl-primary-25: #ffffff;

  --kl-accent-900: #ffda44; // accent color
  --kl-accent-800: #ffde57;
  --kl-accent-700: #ffe169;
  --kl-accent-600: #ffe57c;
  --kl-accent-500: #ffe98f;
  --kl-accent-400: #ffeda2;
  --kl-accent-300: #fff0b4;
  --kl-accent-200: #fff4c7;
  --kl-accent-100: #fff8da;
  --kl-accent-50: #fffbec;

  // Kalgudi warn color
  --kl-warn-50: #ffebee;
  --kl-warn-100: #ffcdd2;
  --kl-warn-200: #ef9a9a;
  --kl-warn-300: #e57373;
  --kl-warn-400: #ef5350;
  --kl-warn-500: #f44336;
  --kl-warn-600: #e53935;
  --kl-warn-700: #d32f2f;
  --kl-warn-800: #c62828; // Warn color

  // Kalgudi core variables
  --icon-color-primary: #585858 !important;

  --kl-brand-color: var(--kl-primary-700);

  //text colors
  --kl-text-primary: var(--kl-primary-900);
  --kl-text-secondary: #666;
  --kl-text-muted: #66696a;
  --kl-text-success: #43a131;

  //background colors
  --kl-bg-primary: #edf2f6;
  --kl-white: #fff;
  --kl-bg-form-color: #f6f6f9;

  //link-hover-colors
  --kl-link-color: #428bca;
  --kl-link-hover-color: #2a6496;

  //Border colors
  --kl-border-dim: #e3e3e3;

  //Header height
  --kl-header-height: 51px;

  // social links hover colors
  --facebook-color: #385898;
  --instagram-color: #f21da0;
  --linkedin-color: #544bc2;
  --twitter-color: #1da1f2;
  --whatsapp-color: #0ee00e;
  --tumblr-color: #34526f;
  --gmail-color: #d44638;

  --app-theme-primary: var(--kl-primary-700);
  --app-theme-accent: var(--kl-accent-700);
  --app-font-family: "Cairo", sans-serif;
  --app-title-font-family: "Cairo", sans-serif;
  --app-sub-title-font-family: "Cairo", sans-serif;
  --app-description-font-family: "Cairo", sans-serif;

  --ag-grid-font: "agGridBalham";

  --app-dark: #3c3c3c;
  --app-light: #676767;
  --app-green: #388e3c;
  --app-white: white;
  --app-black: black;
  --app-gray: gray;

  --app-link: deepskyblue;

  --app-product-tile-rating: rgb(101, 101, 101);
  --app-product-title-view-details: rgb(255, 179, 0);
  --app-home-indicators-item-color: rgba(255, 255, 255, 0.541);
  --app-slider-slides-backgroung: rgb(158, 158, 158);
  --app-slider-slides-li-caption-color: #fff;
  --app-home-title-color: black;

  --app-header-middle-header-background: #fff;
  --app-header-middle-header-menu-color: rgb(64, 64, 64);
  --app-header-search-background: rgba(223, 223, 223, 0.4);
  --app-header-search-onfocus-background: white;
  --app-header-search-button-background: rgb(76, 76, 76);
  --app-header-search-button-color: white;
  --app-header-right-iteams-color: rgb(76, 76, 76);
  --app-header-right-iteams-login-onhover-background: rgb(66, 66, 66);
  --app-header-cart-count-background: rgb(255, 193, 7);
  --app-header-see-all-cat-color: rgb(244, 88, 0);
  --app-header-see-all-cat-background: white;
  --app-header-see-all-cat-background-onhover: #eee;
  --app-side-nav-sigin-color: black;
  --app-side-nav-register-color: rgb(244, 88, 0);

  --app-auth-links-color: rgb(58, 84, 149);
  --app-auth-text-background: white;
  --app-auth-background: rgb(241, 241, 241);
  --app-sigin-link-disable-color: rgb(164, 176, 204);
  --app-sigin-mat-radio-background: rgb(255, 242, 235);
  --app-footer-background: white;
  --app-footer-text-color: rgb(134, 134, 134);
  --app-footer-sections-background: rgb(23, 35, 55);
  --app-footer-sections-content-color: rgb(76, 76, 76);
  --app-footer-prom-title: rgb(76, 76, 76);
  --app-footer-promise-title-background: white;
  --app-footer-promise-bar-background: rgb(5, 11, 23);
  --app-footer-promise-bar-color: rgb(76, 76, 76);

  --app-cart-summary-text-color: rgb(90, 90, 90);
  --app-empty-cart-color: rgb(90, 90, 90);
  --app-cart-remove-icon: rgb(90, 90, 90);

  --app-fullview-headings: rgb(60, 60, 60);
  --app-fullview-links: rgb(58, 84, 149);
  --app-fullview-section: rgb(103, 103, 103);
  --app-fullview-productImage: white;
  --app-fullview-gallery: rgb(103, 103, 103);
  --app-fullview-eligibilities: white;
  --app-fullview-productInfo-links: rgb(103, 103, 103);
  --app-fullview-productInfo-linksHover: rgb(58, 84, 149);
  --app-fullview-productMainDetails: rgb(60, 60, 60);
  --app-fullview-sellerName: rgb(58, 84, 149);
  --app-fullview-productPrice: rgb(60, 60, 60);
  --app-fullview-checkDelivery: rgb(139, 139, 139);
  --app-fullview-productQuantity: rgb(103, 103, 103);
  --app-fullview-productQuantity-icon: rgb(103, 103, 103);
  --app-fullview-addtoCart-button: white;
  --app-fullview-wishlist: rgba(0, 0, 0, 0.87);
  --app-fullview-wishlist-heart: rgba(255, 0, 0, 0.612);

  --app-border-color: #e6e6e6;

  --app-font-1_6rem: 1.6rem;
  --app-font-2_6rem: 2.6rem;

  --app-fullview-image: font(50);
  --app-fullview-gallery-zoom: font(28);
  --app-fullview-gallery: font(14);
  --app-fullview-image-zoom-icon: font(14);
  --app-fullview-eligibilities-text: font(13);
  --app-fullview-productinfo-breadcrumb: font(12);
  --app-fullview-productName: font(21);
  --app-fullview-sellerDetails: font(14);
  --app-fullview-rating: font(13);
  --app-fullview-price: font(24);
  --app-fullview-delivery: font(14);
  --app-fullview-location: font(12);
  --app-fullview-quantity: font(20);
  --app-fullview-productUnit: font(18);
  --app-fullview-productDimensions: font(12);
  --app-fullview-productAvailableIn: font(18);
  --app-fullview-quantityUnit: font(12);
  --app-fullview-multipleDimension: font(15);
  --app-fullview-seller: font(22);
  --app-fullview-sellerReviews: font(18);
  --app-fullview-reviwerName: font(14);
  --app-fullview-reviewerRating: font(13);
  --app-fullview-reviewText: font(12);
  --app-fullview-relatedTitle: font(24);
  --app-fullview-starRating: font(13);
  --app-fullview-sku-elements: font(15);
  --app-fullview-starIcon: font(10);
  --app-fullview-ratingPoint: font(12);
  --app-fullview-stock: font(14);
  --app-fullview-mat-tab: font(14);
  --app-fullview-matTab-h4: font(16);
  --app-fullview-productName-resp: font(20);
  --app-fullview-policyText: font(12);
  --app-fullview-galleryBtn: font(12);
  --app-fullview-galleryBtn-icon: font(20);
  --app-fullview-matCard-title: font(16);
  --app-fullview-policy-resp: font(14);
  --app-fullview-ratingValue-resp: font(12);
  --app-fullview-star: font(12);
  --app-fullview-ratingsReviews: font(22);
  --app-fullview-star-icon: font(15);
  --app-fullview-reviewratings: font(13);
  --app-fullview-productreview: font(14);
  --app-fullview-avgRating: font(32);
  --app-fullview-ratingsReviews-respo: font(17);
  --app-fullview-avgRating-respo: font(21);
  --app-fullview-allviews-respo: font(11);
  --app-fullview-productinfo-respo: font(18);

  --app-header-middle-header: font(14);
  --app-header-menu: font(26);
  --app-header-app-title: font(30);
  --app-header-search-input: font(16);
  --app-header-search-input-placeholder: font(14);
  --app-header-cart-count: font(10);
  --app-header-logidin-items: font(13);
  --app-header-bottom-allcategories: font(14);
  --app-header-allcategories-left-item: font(14);
  --app-header-allcategories-left-item-list: font(10);
  --app-header-category-name: font(15);
  --app-header-logidin-item: font(12);
  --app-header-app-switch: font(18);
  --app-header-app-language-telugu: font(16);
  --app-header-app-language-switch-angle: font(14);
  --app-side-nav-title: font(12);
  --app-side-nav-login-name: font(15);
  --app-side-nav-language: font(12);
  --app-side-nav-all-categories: font(16);
  --app-side-nav-all-categories-down-angle: font(14);
  --app-side-nav-all-categories-items: font(14);
  --app-mobile-app-title: font(26);
  --app-mobile-search-icon: font(27);
  --app-footer-section: font(14);
  --app-footer-title: font(15);
  --app-forget-password-title: 1.6rem;
  --app-auth-button: font(16);
  --app-auth-title: 2.6rem;
  --app-cart-you-pay: font(20);
  --app-cart-total-amount: font(20);
  --app-cart-stepper-text: font(12); /* In mobile view */
  --app-empty-cart-icon: font(150);
  --app-cart-GST-text: font(14);
  --app-empty-cart-continue-button: font(15);
  --app-cart-item-total: font(14);

  // font Weights
  --app-header-search-font-weight-lighter: 500;
  --app-footer-title-ligther: 500;
  --app-auth-title-ligther: 400;

  // Ap greens variables
  --header-item-padding: 14px 25px;

  --menu-item-active-background: var(--kl-primary-700);
  --kl-home-cat-bg: #e9eef280;
  --kl-category-separator-bg: #51749542;

  --kl-light-color: #c4c4c4;
  // / Ap greens variables

  //All apps color
  --kl-input-store-color: #60be74;
  --kl-andhragreen-color: #fd5e40;
  --kl-outputstore-color: #0277bd;
  --kl-ebharati-store-color: #c36768;



  --kl-header-height: 48px;
  
  --kl-full-view-header-height: 66px;

   // footer theme colors
   --kl-footer-bg-left: #ff535129; 
   --kl-footer-bg-right: #F8F8FA; 

   --kl-follow-us: #867F7F;

   --kl-tag-line: #FF5351;
   --kl-end-footer-bg: #E6E6FF;
   --kl-end-footer-color: #5B6C8F;

   --kl-tag-line-color: #103178;

   --kl-store-color: #1D1D3C;

   --kl-login-btn-color: #26bf91;
}

@import "~@angular/material/theming";
@import "./variables/css-variables";
@import './typograpy.scss';


$mango-mart-primary: (
  900: var(--kl-primary-900),
  // Primary color (If you change this color, you have to change it in variable.scss file also)
  800: var(--kl-primary-800),
  700: var(--kl-primary-700),
  600: var(--kl-primary-600),
  500: var(--kl-primary-500),
  400: var(--kl-primary-400),
  300: var(--kl-primary-300),
  200: var(--kl-primary-200),
  100: var(--kl-primary-100),
  50: var(--kl-primary-50),
  contrast: ()
);

$mango-mart-accent: (
  900: var(--kl-accent-900),
  // Accent color
  800: var(--kl-accent-800),
  700: var(--kl-accent-700),
  600: var(--kl-accent-600),
  500: var(--kl-accent-500),
  400: var(--kl-accent-400),
  300: var(--kl-accent-300),
  200: var(--kl-accent-200),
  100: var(--kl-accent-100),
  50: var(--kl-accent-50),
  contrast: ()
);


$mango-mart-warn: (
  50: var(--kl-warn-50),
  100: var(--kl-warn-100),
  200: var(--kl-warn-200),
  300: var(--kl-warn-300),
  400: var(--kl-warn-400),
  500: var(--kl-warn-500),
  600: var(--kl-warn-600),
  700: var(--kl-warn-700),
  800: var(--kl-warn-800),
  // Warn color
  contrast: ()
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();


$core-theme-primary: mat-palette($mango-mart-primary, 900);
$core-theme-accent: mat-palette($mango-mart-accent, 900);
// $core-primary-styles: map.get($kalgudi-core);

// The warn palette is optional (defaults to red).
$core-theme-warn: mat-palette($mango-mart-warn, 800);

// Create the theme object (a Sass map containing all of the palettes).
$kalgudi-core-theme: mat-light-theme($core-theme-primary, $core-theme-accent, $core-theme-warn);

@include angular-material-theme($kalgudi-core-theme);


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--app-font-family);
  text-rendering: optimizeLegibility;
  font-size: 1em;
  -webkit-tap-highlight-color: transparent;

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    overflow-anchor: none; // https://support.google.com/chrome/thread/62861011?hl=en
  }

  &.other-language {
    letter-spacing: 0.4px;
    font-size: 0.94em;

    * {
      line-height: 1.5;
    }
  }

  @media only screen and (max-width: 1280px) and (min-width: 599px) {
    font-size: 0.95em !important;
  }

  @media screen and (max-width: 599px) {
    font-size: 0.9em !important;
  }
}

h1,
h2,
h3,
h4,
div,
p,
a,
span {
  font-family: var(--app-font-family) !important;
}
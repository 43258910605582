
ul {
    padding: 0;
    list-style: none;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.capitalize {
  text-transform: capitalize;
}
